import React, { useState } from 'react'
import Banner from '../components/banner'
import useAPI from '../hooks/use-api'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Contact() {
  const notifySuccess = massage => toast.success(massage)
  const notifyError = massage => toast.error(massage)
  const [state, setState] = useState({
    fullname: '',
    email: '',
    subject: '',
    description: '',
  })
  const [disable, setDisable] = useState(false)
  const api = useAPI()

  const handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    setState(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = async event => {
    event.preventDefault()
    document.getElementById('fieldset').disabled = true
    setDisable(true)
    const response = await api.sendRequest(state)
    if (response === '') {
      setState({
        fullname: '',
        email: '',
        subject: '',
        description: '',
      })
      document.getElementById('fieldset').disabled = false
      setDisable(false)
      notifySuccess('ข้อมูลของท่านได้ถูกส่งให้เจ้าหน้าที่เรียบร้อยแล้ว')
    } else {
      setState({
        fullname: '',
        email: '',
        subject: '',
        description: '',
      })
      document.getElementById('fieldset').disabled = false
      setDisable(false)
      notifyError('ระบบขัดข้อง กรุณาส่งข้อมูลใหม่อีกครั้ง')
    }
  }

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="hidden md:block w-full">
        <Banner
          src={require('@/assets/img/desktop_banner_contact.jpg').default}
          // src={`https://weblink.shopeepay.co.th/img/seainsure/desktop_banner_contact.jpg`}
          style={{ width: '100%' }}
          loading="lazy"
        />
      </div>

      <div className="md:hidden w-full">
        <Banner
          className="object-fit"
          src={require('@/assets/img/mobile_banner_contact.jpg').default}
          // src={`https://weblink.shopeepay.co.th/img/seainsure/mobile_banner_contact.jpg`}
          style={{ width: '100%' }}
          loading="lazy"
        />
      </div>
      <div className="m-auto my-10" style={{ maxWidth: '1260px' }}>
        <h1 className="text-center mb-10">ติดต่อเรา</h1>
        <div className="flex flex-wrap justify-between">
          <div className="w-full px-5 md:w-5/12">
            <p className="contact-text-h mb-6">ติดต่อ/ร้องเรียน</p>
            <form onSubmit={handleSubmit}>
              <fieldset id="fieldset">
                <div className="flex flex-wrap mb-4">
                  <label className="contact-text-label mb-2" htmlFor="name">
                    ชื่อ-นามสกุล <span style={{ color: '#EE2C4A' }}>*</span>
                  </label>
                  <input
                    className="border w-full rounded h-10 p-2"
                    type="text"
                    id="name"
                    name="fullname"
                    maxLength="255"
                    value={state.fullname}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="flex flex-wrap mb-4">
                  <label className="contact-text-label mb-2" htmlFor="email">
                    อีเมลของคุณ <span style={{ color: '#EE2C4A' }}>*</span>
                  </label>
                  <input
                    className="border w-full rounded h-10 p-2"
                    type="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    id="email"
                    name="email"
                    maxLength="150"
                    value={state.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="flex flex-wrap mb-4">
                  <label className="contact-text-label mb-2" htmlFor="subject">
                    เรื่องที่ต้องการติดต่อ
                  </label>
                  <input
                    className="border w-full rounded h-10 p-2"
                    type="text"
                    id="subject"
                    name="subject"
                    maxLength="1000"
                    value={state.subject}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex-wrap mb-8">
                  <label className="contact-text-label mb-2" htmlFor="description">
                    รายละเอียดที่ต้องการติดต่อ
                  </label>
                  <div className="w-full">
                    <textarea
                      id="description"
                      name="description"
                      rows="6"
                      className="border w-full rounded p-2"
                      value={state.description}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <input
                    className={
                      disable
                        ? 'submit-button-disabled mx-auto text-white font-bold noSelect'
                        : 'submit-button mx-auto text-white font-bold cursor-pointer noSelect'
                    }
                    type="submit"
                    id="submit"
                    value="ส่งข้อมูล"
                  />
                </div>
              </fieldset>
            </form>
          </div>
          <div className="w-full px-5 md:w-5/12 md:mt-0 mt-6">
            <p className="contact-text-h mb-3 md:mb-8">ติดต่อเรา</p>
            <p className="contact-text mb-10">
              <b>สำนักงานใหญ่</b>
              <br /> ที่อยู่ 1818 อาคารรสาทู ชั้น 26 ถนนเพชรบุรี แขวงมักกะสัน เขตราชเทวี
              กรุงเทพมหานคร 10400
              <br />
              <br />
              <b>รายละเอียดการติดต่อ</b> <br /> โทรศัพท์: 0 2040 8581
              <br /> เวลาทำการ: จันทร์ - ศุกร์ 09.00 - 17.00 น. <br />
              อีเมล: help@seainsurebroker.co.th
            </p>
            <iframe
              title="map"
              src="https://maps.google.com/maps?width=600&amp;height=223&amp;hl=en&amp;q=RASA TWO&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              height="233"
              allowFullScreen=""
              loading="lazy"
              className="hidden md:block w-full"
            ></iframe>
            <iframe
              title="map"
              src="https://maps.google.com/maps?width=600&amp;height=223&amp;hl=en&amp;q=RASA TWO&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              height="341px"
              allowFullScreen=""
              loading="lazy"
              className="md:hidden w-full"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
